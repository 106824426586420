import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Danke"
      keywords={[
        `Hauenstein`,
        `Estriche`,
        `Heizestriche`,
        `Schwimmende Estriche`,
        `Anhydrit-Estriche`,
        `Sanierung`,
        `Unterbodenausgleich`,
        `Bauwerksabdichtungen`,
      ]}
    />

    <div
      style={{
        minHeight: "800px",
        maxWidth: "1080px",
        margin: "0 auto",
        padding: "1rem",
      }}
    >
      <h1>Vielen Dank für ihre Anfrage!</h1>
      <p>Wir werden uns schnellstmöglich darum kümmern.</p>
      <p>
        Zurück zur <Link to="/">Startseite</Link>.
      </p>
    </div>
  </Layout>
)

export default IndexPage
